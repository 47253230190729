import { ProductListActionType } from '@scandipwa/scandipwa/src/store/ProductList/ProductList.type';
import { getIndexedProducts, preloadProductImage, PRODUCTS_PRELOAD_COUNT } from '@scandipwa/scandipwa/src/util/Product';
import { getSmallImage } from '@scandipwa/scandipwa/src/util/Product/Extract';
import {
    ProductListReducer as SourceProductListReducer,
    // getInitialState,
    defaultConfig,
} from 'SourceStore/ProductList/ProductList.reducer';

export {
    // getInitialState,
    defaultConfig,
};

/** @namespace Store/ProductList/Reducer/getInitialState */
export const getInitialState = () => ({
    pages: {},
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    isPageLoading: false,
    currentArgs: {},
    attributes: [],
});

//TODO: implement ProductListReducer
export const ProductListReducer = (
    state = getInitialState(),
    action,
) => {
    const {
        type,
        items: initialItems = [],
        total_pages: totalPages,
        total_count: totalItems,
        currentPage,
        isLoading,
        args: currentArgs,
        attributes,
    } = action;
    const {
        isPrefetchValueUsed,
    } = window;

    switch (type) {
        case ProductListActionType.APPEND_PAGE: {
            return {
                ...state,
                isPageLoading: false,
                pages: {
                    ...state.pages,
                    [currentPage]: initialItems,
                },
            };
        }

        case ProductListActionType.UPDATE_PRODUCT_LIST_ITEMS: {
            if (isPrefetchValueUsed) {
                initialItems.slice(0, PRODUCTS_PRELOAD_COUNT).forEach((item) => {
                    preloadProductImage(getSmallImage(item));
                });
            }

            return {
                ...state,
                currentArgs,
                isLoading: false,
                totalItems,
                totalPages,
                pages: { [currentPage]: initialItems },
                attributes,
            };
        }

        case ProductListActionType.UPDATE_PAGE_LOAD_STATUS: {
            return {
                ...state,
                isPageLoading: true,
            };
        }

        case ProductListActionType.UPDATE_LOAD_STATUS: {
            return {
                ...state,
                isLoading,
            };
        }

        default: {
            return state;
        }
    }
};

export default ProductListReducer;
