import {
    ProductListQuery as SourceProductListQuery,
} from 'SourceQuery/ProductList.query';
import { Field } from '@tilework/opus';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import { SortDirections } from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.config';
import { CUSTOMER } from '@scandipwa/scandipwa/src/route/MyAccount/MyAccount.config';
import { NONE_SORT_OPTION_VALUE } from 'Route/SearchPage/SearchPage.config';

export class ProductListQuery extends SourceProductListQuery {
    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getPriceRangeField()
        ];
    }

    _getCartProductFields() {
        return [
            'id',
            'sku',
            'stock_status',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getAttributesField(true, true),
            this._getPriceRangeField()
        ];
    }

    _getProductInterfaceFields(
        isVariant,
        isForLinkedProducts = false,
        isForWishlist = false,
    ) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            args
        } = this.options;

        if (args?.search && noVariants) {
            const searchfields = [
                'uid',
                'id',
                'sku',
                'name',
                'stock_status',
                this._getProductSmallField(),
            ];

            return searchfields;
        }

        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            // 'salable_qty',
            // this._getStockItemField(),
            this._getPriceRangeField(),
        ];

        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                // this._getProductImageField(),
                // this._getProductThumbnailField(),
                this._getProductSmallField(),
                // this._getShortDescriptionField(),
                // 'special_from_date',
                // 'special_to_date',
                this._getTierPricesField(),
            );
        }

        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant, false));
        }

        if (!isVariant) {
            fields.push(
                'url',
                // this._getUrlRewritesFields(),
                // this._getReviewCountField(),
                // this._getRatingSummaryField(),
                this._getCustomizableProductFragment(),
            );

            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems(),
                );
            }
        }

        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        if (isVariant) {
            fields.push(
                this._getVariantProductThumbnailField(),
                // this._getProductLinksField()
            );
        }

        if (!isSingleProduct) {
            fields.push(
                // this._getCategoriesField(),
                // this._getMediaGalleryField(),
            );
        }

        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getShortDescriptionField(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCategoriesField(),
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField(),
                );
            }
        }

        return fields;
    }

    _getVariantProductThumbnailField() {
        return new Field('thumbnail')
            .addFieldList(this._getVariantProductThumbnailFields());
    }

    _getVariantProductThumbnailFields() {
        return [
            'path',
            'url'
        ];
    }

    _getArgumentsMap() {
        const { requireInfo } = this.options;
        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: {
                type: 'Int!',
                handler: undefined,
            },
            pageSize: {
                type: 'Int!',
                handler: (option) => (requireInfo ? 1 : 12),
            },
            search: {
                type: 'String!',
                handler: (option) => option.replace(/\+/g, ' '),
            },
            sort: {
                type: 'ProductAttributeSortInput!',
                handler: (
                    { sortKey, sortDirection },
                ) => {
                    if (!sortKey || sortKey === NONE_SORT_OPTION_VALUE) {
                        return {};
                    }

                    return { [sortKey]: sortDirection || SortDirections.ASC };
                },
            },
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id || 0,
                    };

                    const {
                        customFilters: { category_id } = {},
                    } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */
                    if (category_id) {
                        options.categoryIds = undefined;
                    }

                    const parsedOptions = Object.entries(options).reduce(
                        (acc, [key, option]) => {
                            // if there is no value, or if the key is just not present in options object
                            if (!option || !filterArgumentMap[key]) {
                                return acc;
                            }

                            return {
                                ...acc,
                                ...filterArgumentMap[key](option),
                            };
                        },
                        {},
                    );

                    return parsedOptions;
                },
            },
        };
    }

    _getMediaGalleryFields() {
        return [
            'id',
            'types',
            this._getMediaBaseField(),
            this._getMediaLargeField(),
        ];
    }

    _getCategoryFields() {
        return [
            'id',
            'name',
            'url',
            this._getIsCategoryActive(),
            this._getBreadcrumbsField(),
            this._getCustomizationInfo(),
        ];
    }

    _getCustomizationInfo() {
        return new Field('customization_info')
            .addFieldList([
                'customization_enable',
                'diamond_link',
                'setting_link',
                'final_sku',
                'is_setting',
                'is_diamond'
            ]);
    }
}

export default new ProductListQuery();
